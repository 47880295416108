import React, { useRef, useState } from 'react'
import { useViewportSpy, useWindowScroll, useMediaQuery } from 'beautiful-react-hooks'
import { setModalOpen } from "layouts/DefaultLayout"
import cn from 'classnames'

import { Store } from 'store'
import shiftDemo from './images/ShiftDemo.png'

import styles from './ShiftDemo.module.scss'

const ShiftDemo = props => {
    const ref = useRef()
    const refShiftDashboard = useRef()
    const {dispatch} = React.useContext(Store)
    // const visible = useViewportSpy(ref)
    const [scrollY, setScrollY] = useState((typeof window !== 'undefined' && window.scrollY) || 0)
    const isMobile = useMediaQuery('(max-width: 767px)')

    useWindowScroll((event) => {
        const value = ((typeof window !== 'undefined' && window.scrollY) || 0) / 210
        if (!isMobile) {
            setScrollY(value >= 25 ? 25 : value)
        }
    })

    return (
        <div className={styles.shiftDemo}>
            <div className={styles.container}>
                <div className={styles.leftSide}>
                    <div
                        className={styles.shiftDemoImage}
                        style={{
                            backgroundImage: `url(${shiftDemo})`,
                            width: 178,
                            height: 54,
                        }}
                    />

                    <h2 className={styles.shiftDemoTitle}>
                        <div
                            className={cn(styles.shiftDemoImage, styles.shiftDemoImageMobile)}
                            style={{
                                backgroundImage: `url(${shiftDemo})`,
                                width: 178,
                                height: 54,
                            }}
                        />
                        Results matter.
                    </h2>

                    <p className={styles.shiftDemoP}>
                        Manage all your phonathon and direct marketing <br />
                        campaigns and track performance in real-time using <br />
                        our Shift One application.
                    </p>

                    <a
                        onClick={(e) => {
                            e.preventDefault();
                            setModalOpen(dispatch, 'highered'
                        )}}
                        className="sh-button-primary __large"
                        href="#">
                        Book a demo today!
                    </a>
                </div>

                <div className={styles.rightSide} />
            </div>

            <span
                className={styles.bubble}
                // style={
                //     isMobile
                //         ? null
                //         : {
                //               right: '-28vw',
                //               transform: visible ? `translateX(-15)vw` :`translateX(-${scrollY}vw)`
                //           }
                // }
            />

            <span className={styles.shiftDashboard} ref={refShiftDashboard} style={isMobile ? null : {
            }} />

            <div className={styles.visibleArea} ref={ref} />
        </div>
    )
}

export default ShiftDemo
