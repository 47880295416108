import React from 'react'
import cn from 'classnames'

import Layout from 'layouts/DefaultLayout'
import SEO from 'components/SEO'
import Topbar from 'components/Topbar/Topbar'
import HigheredHero from '../page-components/Highered/HigheredHero/HigheredHero'
import HigheredBrands from '../page-components/Highered/HigheredBrands/HigheredBrands'
import Engaging from '../page-components/Highered/Engaging/Engaging'
import HigheredTestimonial from '../page-components/Highered/HigheredTestimonial/HigheredTestimonial'
import Phonathon from '../page-components/Highered/Phonathon/Phonathon'
import FooterCTA from 'components/FooterCTA'
import Separator from 'components/Separator/Separator'
import HigheredPosters from '../page-components/Highered/HigheredPosters/HigheredPosters'
import HigherEdAccordion from 'components/HigherEdAccordion/HigherEdAccordion'
import Dashboard from '../page-components/Highered/Dashboard/Dashboard'
import BookADemo from 'components/BookADemo/BookADemo'
import ShiftDemo from '../page-components/Highered/ShiftDemo/ShiftDemo'
import thumbnail from 'images/shift-highered-thumbnail.jpg'

import styles from './highered.module.scss'

function HigherEdPage () {
    return (
        <Layout
            modalType="highered"
            logoText="Marketing"
            header={
                <>
                    <Topbar />
                </>
            }
        >
            <SEO
                title='Higher Education'
                description='Engage with your constituents and encourage their generosity, leading directly to donations.'
                image={`https://shiftmarketing.io${thumbnail}`}
                canonical={'https://shiftmarketing.io/highered'}
                ogUrl={'https://shiftmarketing.io/highered'}
                siteName={'Higher Education'}
            />

            <HigheredHero />
            <HigheredBrands />
            <Engaging />
            <HigherEdAccordion />
            <BookADemo />
            <HigheredPosters />

            <div className={styles.separator}>
                <Separator />
            </div>

            <Dashboard />

            <div className={cn(styles.separator, styles.separatorMobile)}>
                <Separator />
            </div>

            <ShiftDemo />
            <Phonathon />
            <HigheredTestimonial />
            <FooterCTA modalType={'highered'}/>
        </Layout>
    )
}

export default HigherEdPage
