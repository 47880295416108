import React from "react";

function IconNext ({
	backgroundColor = '#FFFFFF'
}) {
  return (
      <svg
        width={70}
        height={70}
        viewBox="0 0 70 70"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{"ICON-SliderArrow Copy"}</title>
        <g fill="none" fillRule="evenodd">
          <rect
            stroke="#EC232F"
            strokeWidth={2}
            fill="#EC232F"
            x={1}
            y={1}
            width={68}
            height={68}
            rx={34}
          />
          <path
            d="M23.247 33.241l17.8-9.642A2 2 0 0144 25.358v19.284a2 2 0 01-2.953 1.759l-17.8-9.642a2 2 0 010-3.518z"
            fill="#FFF"
          />
        </g>
      </svg>
  );
}

export default IconNext;
