import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useMediaQuery } from 'beautiful-react-hooks'
import cn from 'classnames'

import IconPrev from './IconPrev'
import IconNext from './IconNext'
import mail from './images/mail.png'
import marketing from './images/marketing.png'
import alumni2 from './images/alumni2.png'
import phone from './images/phone.png'
import voicemail from './images/voicemail.png'
import phonathon from './images/phonathon.png'

import styles from './HigheredPosters.module.scss'

const SLIDES = [
    {
        description: 'Print / Direct Mail',
        uri: mail
    },
    {
        description: 'Email marketing',
        uri: marketing
    },
    {
        description: 'Landing pages',
        uri: alumni2
    },
    {
        description: 'Text messaging',
        uri: phone
    },
    {
        description: 'Ringless voicemail',
        uri: voicemail
    },
    {
        description: 'Phonathon',
        uri: phonathon
    }
]

const HigheredPosters = () => {
    const swiperRef = useRef(null)
    const [onReachEnd, setReachEnd] = useState(false)
    const [onBeginning, setBeginning] = useState(false)
    const isMobile = useMediaQuery('(max-width: 992px)')

    const goNext = () => {
        if (!onReachEnd && swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext()
        }
    }

    const goPrev = () => {
        if (!onBeginning && swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev()
        }
    }

    return (
        <section className={styles.higheredPosters}>
            <h2 className={styles.title}>
                Created with your Insitutions' high standards in mind.
            </h2>
            <Swiper
                spaceBetween={15}
                centeredSlides
                slidesPerView={1.32}
                initialSlide={Math.ceil(SLIDES.length / 2)}
                breakpoints={{
                    320: {
                        slidesPerView: 1.32,
                        spaceBetween: 15,
                    },
                    460: {
                        slidesPerView: 1.52,
                        spaceBetween: 15,
                    },
                    768: {
                        slidesPerView: 2.99,
                        spaceBetween: 30,
                    },
                    1200: {
                        slidesPerView: 4.5,
                        spaceBetween: 30,
                    },
                    1440: {
                        slidesPerView: 5.2,
                        spaceBetween: 10,
                    },
                }}
                onProgress={progress => {
                    setBeginning(progress.isBeginning)
                    setReachEnd(progress.isEnd)
                }}
                ref={swiperRef}
            >
                {SLIDES.map(({
                    description,
                    uri
                }, i) => (
                    <SwiperSlide style={{ width: '280px' }} key={i}>
                        <div
                            className={styles.poster}
                            style={{
                                backgroundImage: `url(${uri})`
                            }}
                        >
                            <div className={styles.description}>
                                {description}
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            <span className={styles.swiperNavigationContainer}>
                <div
                    className={cn(styles.btn, styles.btnLeft, {
                        [styles.disable]: onBeginning,
                    })}
                    onClick={goPrev}
                >
                    <IconPrev backgroundColor={'#EC1B2E'} />
                </div>

                <div
                    className={cn(styles.btn, styles.btnNext, {
                        [styles.disable]: onReachEnd,
                    })}
                    onClick={goNext}
                >
                    <IconNext backgroundColor={'#EC1B2E'} />
                </div>
            </span>

            <div className={styles.section}>
                <p className={styles.paragraph}>
                    Shift Higher Education understands the importance of brand
                    standards, brand consistency and delivering a high quality
                    constituent
                    experience. We will meet the highest creative standards
                    while utilizing templated campaign structures that are ideal
                    for constituent
                    engagement and timely execution. With various templates, the
                    ability to customize your event with a school logo, fonts,
                    images and colors,
                    your campaign will reflect your desired message and be
                    easier to create, proof and execute.
                </p>
            </div>
        </section>
    )
}

const formatMessage = (message, slice) => {
    if (slice) {
        return `“${message.slice(0, 200)}${message.length >= 200 ? '...' : ''}”`
    }

    return `“${message}”`
}

export default HigheredPosters
