import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useMediaQuery } from 'beautiful-react-hooks'
import cn from 'classnames'
import IconPrev from './IconPrev'
import IconNext from './IconNext'

import stars from './images/stars.png'

import styles from './HigheredTestimonial.module.scss'

const SLIDES = [
    {
        message: 'The thing that I love most about working with Shift Marketing is that our team can now partner with a singular vendor to engage constituents through multiple touchpoints. The first campaign we conducted with Shift even helped us to produce more in six months than what we were previously able to produce over an entire fiscal year!',
        firstName: 'Aron D.',
        lastName: 'Arceneaux',
        position: "Development Coordinator Lamar University",
        stars: stars,
    },
    {
        message: 'The Shift team approaches everything it does with a 21st century mindset, so putting together highly personalized multichannel campaigns is right in their wheelhouse.  Such expertise has enhanced our ability to effectively communicate with our constituents and increase donor activity.',
        firstName: 'Roxanne',
        lastName: 'Engellant',
        position: 'Executive Director, The University of Montana Western Foundation & Alumni Office',
        stars: stars,
    },
    {
        message: 'We have partnered with Shift Marketing on several campaigns, and have been highly impressed with both the service and the results. Within 2-3 weeks of delivery, our most recent appeal produced more than five times what our investment was, and - as always - Shift made the entire process incredibly easy for our team!',
        firstName: 'Valerie',
        lastName: 'Fessler',
        position: 'Director of Development & Community Engagement, American Rescue Workers',
        stars: stars,
    }
]

const HigheredTestimonial = () => {
    const swiperRef = useRef(null)
    const [onReachEnd, setReachEnd] = useState(false)
    const [onBeginning, setBeginning] = useState(false)
    const isMobile = useMediaQuery('(max-width: 992px)')

    const goNext = () => {
        if (!onReachEnd && swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext()
        }
    }

    const goPrev = () => {
        if (!onBeginning && swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev()
        }
    }

    return (
        <section className={styles.higheredTestimonial}>
            <h2 className={styles.title}>Don't just take our word for it!</h2>
                <Swiper
                    spaceBetween={30}
                    centeredSlidesBounds
                    centeredSlides
                    slidesPerView={1}
                    initialSlide={2}
                    breakpoints={{
                        320: {
                            slidesPerView: 1.5,
                            spaceBetween: 22
                        },
                        768: {
                            slidesPerView: 2.5,
                            spaceBetween: 20,
                        },
                        1200: {
                            slidesPerView: 2.9,
                            spaceBetween: 30
                        },
                        1440: {
                            slidesPerView: 4,
                            spaceBetween: 30
                        }
                    }}
                    onProgress={progress => {
                        setBeginning(progress.isBeginning)
                        setReachEnd(progress.isEnd)
                    }}
                    ref={swiperRef}
                >
                       <SwiperSlide style={{ width: '480px' }} />
                       {SLIDES.map(({
                           message,
                           stars,
                           firstName,
                           lastName,
                            position
                       }, i) => (
                            <SwiperSlide style={{ width: '400px' }}>
                                <div className={styles.testimonialContainer} key={i}>
                                    <div
                                        className={styles.stars}
                                        style={{
                                            backgroundImage: `url(${stars})`,
                                        }}
                                    />

                                  <div className={styles.testimonial}>
                                        <p className={styles.testimonialP}>
                                            {formatMessage(message, isMobile)}
                                        </p>

                                        <div className={styles.testimonialMeta}>
                                            <span>
                                                {firstName} {lastName}, &nbsp;
                                            </span>
                                            {position}
                                        </div>
                                  </div>
                                </div>
                            </SwiperSlide>
                       ))}
                        <SwiperSlide style={{ width: '480px' }} />
                </Swiper>


            {/*<span className={styles.swiperNavigationContainer}>*/}
            {/*    <div className={cn(styles.btn, styles.btnLeft, {*/}
            {/*        [styles.disable]: onBeginning,*/}
            {/*    })} onClick={goPrev}>*/}
            {/*        <IconPrev backgroundColor={'#EC1B2E'} />*/}
            {/*    </div>*/}

            {/*    <div*/}
            {/*        className={cn(styles.btn, styles.btnNext, {*/}
            {/*            [styles.disable]: onReachEnd,*/}
            {/*        })}*/}
            {/*        onClick={goNext}*/}
            {/*    >*/}
            {/*        <IconNext backgroundColor={'#EC1B2E'} />*/}
            {/*    </div>*/}
            {/*</span>*/}
        </section>
    )
}

const formatMessage = (message, slice) => {
    if (slice) {
        return `“${message.slice(0, 200)}${message.length >= 200 ? '...' : ''}”`
    }

    return `“${message}”`
}

export default HigheredTestimonial
