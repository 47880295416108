import React from 'react'

import dominican from './images/dominican.jpg'
import franklin from './images/franklin.jpg'
import easternU from './images/easternU.jpg'
import baruchCollege from './images/baruchCollege.jpg'
import aadc from './images/aadc.jpg'

import styles from './HigheredBrands.module.scss'

const HigheredBrands = () => {
    return (
        <section className={styles.brands}>
            <div className={styles.logosContainer}>
                <div className={styles.logo}>
                    <div style={{
                        backgroundImage: `url(${easternU})`,
                        width: '137px',
                        height: '56px'
                    }} />
                </div>
                <div className={styles.logo}>
                    <div style={{
                        backgroundImage: `url(${franklin})`,
                        width: '170px',
                        height: '32px'
                    }} />
                </div>
                <div className={styles.logo}>
                    <div style={{
                        backgroundImage: `url(${baruchCollege})`,
                        width: '129px',
                        height: '58px'
                    }} />
                </div>
                <div className={styles.logo}>
                    <div style={{
                        backgroundImage: `url(${aadc})`,
                        width: '141px',
                        height: '64px'
                    }} />
                </div>
                <div className={styles.logo}>
                    <div style={{
                        backgroundImage: `url(${dominican})`,
                        width: '140px',
                        height: '53px'
                    }} />
                </div>
            </div>
        </section>
    );
};

export default HigheredBrands
