import React, { useState, useRef } from 'react'
import cn from 'classnames'
import MediaQuery from 'react-responsive'

import { Store } from 'store'
import { setModalOpen } from "layouts/DefaultLayout"
import { useWindowScroll, useMediaQuery, useViewportSpy } from 'beautiful-react-hooks'

import styles from './HigheredHero.module.scss'

const HigheredHero = () => {
    const ref = useRef()
    const {dispatch} = React.useContext(Store)
    const [scrollY, setScrollY] = useState((typeof window !== 'undefined' && window.scrollY) || 0)

    useWindowScroll(event => {
        setScrollY((typeof window !== 'undefined' && window.scrollY) || 0)
    })

    return (
        <section className={styles.hero} ref={ref}>
            <span className={styles.women} />

            <span className={cn(styles.whiteBubble, styles.animationBase)}/>

            <span className={cn(styles.animationBase, styles.blueBubble)}/>

            <span className={cn(styles.animationBase, styles.greenBubble)} />

            <div className={styles.innerSection}>
                <div className={styles.left}>
                    <div className={styles.leftInner}>
                        <MediaQuery minWidth={769}>
                            <h1 className={styles.title}>
                                Engaging your alumni <br /> for a brighter future.
                            </h1>
                            <p className={styles.subtitle}>
                                Engage with your constituents and <br /> encourage their generosity, leading <br /> directly to donations.
                            </p>
                            <div className={styles.btnContainer}>
                                <button onClick={() => setModalOpen(dispatch, 'highered')} className="sh-button-primary __large" style={{ width: '210px' }}>Book a demo</button>
                            </div>
                        </MediaQuery>
                        <MediaQuery maxWidth={768}>
                            <h1 className={styles.title}>
                                Engaging your alumni <br /> for a brighter future.
                            </h1>
                        </MediaQuery>
                    </div>
                </div>

                <div className={styles.right} />
                <MediaQuery maxWidth={768}>
                    <div className={styles.btnContainer}>
                        <button onClick={() => setModalOpen(dispatch, 'highered')} className="sh-button-primary __large" style={{ width: '210px' }}>Book a demo</button>
                    </div>
                </MediaQuery>
            </div>
        </section>
    );
};

export default HigheredHero
