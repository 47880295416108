import React, { useRef, useState } from 'react'
import { Store } from 'store'
import cn from 'classnames'
import {
    useViewportSpy,
    useWindowScroll,
    useMediaQuery,
} from 'beautiful-react-hooks'

import styles from './Dashboard.module.scss'

import { setModalOpen } from 'layouts/DefaultLayout'

function Dashboard (props) {
    const ref = useRef()
    const refShiftDashboard = useRef()
    const { dispatch } = React.useContext(Store)
    // const visible = useViewportSpy(ref)
    const visibleShiftDashboard = useViewportSpy(refShiftDashboard)
    const [scrollY, setScrollY] = useState(
        (typeof window !== 'undefined' && window.scrollY) || 0
    )
    const isMobile = useMediaQuery('(max-width: 767px)')

    useWindowScroll(event => {
        const value =
            ((typeof window !== 'undefined' && window.scrollY) || 0) / 120
        if (!isMobile) {
            setScrollY(value >= 35 ? 35 : value)
        }
    })

    return (
        <>
            <h2 className={cn(styles.dashboardTitle, styles.dashboardTitleMobile)}>Giving Pages</h2>
            <div className={styles.dashboard}>
                <div className={styles.container}>
                    <div className={styles.lefSide} />
                    <div
                        className={styles.rightSide}
                        style={
                            isMobile
                                ? null
                                : {
                                      left: visibleShiftDashboard
                                          ? '0em'
                                          : '-0.6em',
                                      position: 'relative',
                                  }
                        }
                    >
                        <h2 className={styles.dashboardTitle}>Giving Pages</h2>

                        <p className={styles.dashboardP}>
                            With online giving on the rise, building an online experience that
                            that drives engagement and donor generosity is critical to
                            success. Our simple, beautiful and mobile friendly giving pages
                            give your organization the tools it needs to succeed online.
                        </p>

                        <a
                            onClick={e => {
                                e.preventDefault()
                                setModalOpen(dispatch, 'higher_ed_product_sheet_giving_pages')
                            }}
                            className="sh-button-primary __large"
                            href="#"
                        >
                            Download product sheet
                        </a>

                        <p className={styles.donation}>
                            Shift Marketing Selects Double the Donation as our Matching Gift Software Provider
                        </p>

                        <div className={styles.double} />
                    </div>
                </div>

                <span
                    className={styles.yellowBubble}
                    // style={
                    //     isMobile
                    //         ? null
                    //         : {
                    //               left: '-35vw',
                    //               transform: visible ? `translateX(10)vw` :`translateX(${scrollY}vw)`
                    //           }
                    // }
                />

                <span
                    className={styles.shiftDashboard}
                    ref={refShiftDashboard}
                />
                <div className={styles.visibleArea} ref={ref} />
            </div>
        </>
    )
}

export default Dashboard
