import React from "react"

import styles from './BookADemo.module.scss'

import { Store } from 'store'
import { setModalOpen } from "layouts/DefaultLayout";

import bg from 'images/higher_ed/book_an_appointment_bg.jpg'

const BookADemo = (props) => {
    const {dispatch} = React.useContext(Store)

    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <div className={styles.logo} />

                <h1 className={styles.title}>
                    Colleges and universities are choosing to partner with <br/>
                    <strong>Shift</strong> in order to optimize their constituent engagement <br/>
                    and fundraising campaigns.
                </h1>

                <button onClick={() => setModalOpen(dispatch, 'highered')} className="sh-button-primary __large"
                        style={{width: 210}}>Book a demo
                </button>
            </div>
        </div>
    )
}

export default BookADemo
