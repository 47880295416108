import React from "react"

import styles from './Topbar.module.scss'

const Topbar = () => {
    const [showTopbar, setShowTopbar] = React.useState(false)

    React.useEffect(() => {
        window.location.hash === '#stockade' ? setShowTopbar(true) : setShowTopbar(false)
    }, [])

    return (
        <div className={styles.container} style={{display: showTopbar ? 'block' : 'none'}}>
            <div className={styles.innerContainer}>
                The Stockade Consulting Group is now Shift Marketing Higher Education.
            </div>
        </div>
    )
}

export default Topbar
