import React, { useCallback, useState } from "react"
import cn from 'classnames'

import styles from './HigherEdAccordion.module.scss'

function HigherEdAccordion () {
    const [activeStep, setActiveStep] = useState(1)

    const handleStep1 = useCallback(() => {
        // if (activeStep === 1) {
        //     return setActiveStep(null)
        // }
        setActiveStep(1)
    }, [ activeStep, setActiveStep ])

    const handleStep2 = useCallback(() => {
        // if (activeStep === 2) {
        //     return setActiveStep(null)
        // }
        setActiveStep(2)
    }, [ activeStep, setActiveStep ])

    const handleStep3 = useCallback(() => {
        // if (activeStep === 3) {
        //     return setActiveStep(null)
        // }
        setActiveStep(3)
    }, [ activeStep, setActiveStep ])

    const handleStep4 = useCallback(() => {
        // if (activeStep === 4) {
        //     return setActiveStep(null)
        // }
        setActiveStep(4)
    }, [ activeStep, setActiveStep ])
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <div className={styles.leftSide}>
                    <h3 className={styles.title}>
                        Shift’s 4 step recipe to a <br />
                        successful campaign.
                    </h3>

                    <div className={styles.menuContainer}>
                        <div className={cn(styles.menuItem, {
                            [styles.active]: activeStep === 1
                        }, styles.menuData)} onClick={handleStep1}>
                            <div className={styles.itemTitle}>
                                Data
                            </div>

                            <p className={styles.itemText}>
                                <div className={cn(styles.stepImageMobile, styles.step1, {
                                    [styles.active]: activeStep === 1
                                })} />

                                Clean, filter and segment your data to ensure your message reaches
                                the right audience.
                            </p>
                        </div>

                        <div className={cn(styles.menuItem, {
                            [styles.active]: activeStep === 2
                        }, styles.menuCommunication)} onClick={handleStep2}>
                            <div className={styles.itemTitle}>
                                Communication
                            </div>
                            <p className={styles.itemText}>
                                <div className={cn(styles.stepImageMobile, styles.step2, {
                                    [styles.active]: activeStep === 2
                                })} />

                                Cross-media, highly personalized communication to capture attention
                                wherever constituents are most likely to respond.
                            </p>
                        </div>

                        <div className={cn(styles.menuItem, {
                            [styles.active]: activeStep === 3
                        }, styles.menuLanding)} onClick={handleStep3}>
                            <div className={styles.itemTitle}>
                                Landing pages
                            </div>
                            <p className={styles.itemText}>
                                <div className={cn(styles.stepImageMobile, styles.step3, {
                                    [styles.active]: activeStep === 3
                                })} />

                                Maximize conversion with impeccably designed landing pages. Crafted
                                to enhance your brand and create a user friendly constituent experience.
                            </p>
                        </div>

                        <div className={cn(styles.menuItem, {
                            [styles.active]: activeStep === 4
                        }, styles.menuTracking)} onClick={handleStep4}>
                            <div className={styles.itemTitle}>
                                Tracking and analytics
                            </div>
                            <p className={styles.itemText}>
                                <div className={cn(styles.stepImageMobile, styles.step4, {
                                    [styles.active]: activeStep === 4
                                })} />

                                Simple, easy-to-use CRM to make it easy to follow up. Campaign
                                analytics dashboard to track the success of the campaign in real-time.
                            </p>
                        </div>

                    </div>
                </div>

                <div className={styles.info}>
                    <div className={cn(styles.stepImage, styles.step1, {
                        [styles.active]: activeStep === 1
                    })} />
                    <div className={cn(styles.stepImage, styles.step2, {
                        [styles.active]: activeStep === 2
                    })} />
                    <div className={cn(styles.stepImage, styles.step3, {
                        [styles.active]: activeStep === 3
                    })} />
                    <div className={cn(styles.stepImage, styles.step4, {
                        [styles.active]: activeStep === 4
                    })} />
                </div>
            </div>
        </div>
    )
}

export default HigherEdAccordion